import { Form, InputNumber, Radio, Select, Tooltip } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { ImpulseUnit } from '../../../constants/impulseUnitEnum'
import CustomInputCoordinates from '../../CustomInputCoordinates'
import { CoordinatesRules } from '../../../constants/coordinatesRules'
import { GenericMode } from '../../../constants/genericModeEnum'
import SelectCriterias from '../../CustomSelectCriterias'
import { SpecificFormContext } from '../../../contexts/specificFormContext'
import { ImpulseValue } from '../../../constants/impulseValueEnum'
import { FALSE } from 'ol/functions'

interface Props {
    criterias: CriteriaAPI[]
    entryCoords: string | undefined
    exitCoords: string | undefined
}

/**
 * Different components of the form that need the generic speed to work
 * @returns Different form items
 */
const BaseSpecificGenericSpeed = ({ criterias, entryCoords, exitCoords }: Props) => {
    const [entryCoordsState, setEntryCoordsState] = useState<any>(entryCoords)
    const [exitCoordsState, setExitCoordsState] = useState<any>(exitCoords)
    const [impulsesUnit, setImpulsesUnit] = useState<string>(ImpulseUnit.SECONDS)
    const [impulsesValue, setImpulsesValue] = useState<number>(ImpulseValue.SECONDS)
    const [mode, setMode] = useState<number>(GenericMode.COORDINATES)
    const [criteria, setCriteria] = useState(null)
    const form = useContext(SpecificFormContext)

    useEffect(() => {
        if (form) {
            setCriteria(form.form?.getFieldValue('criteria'))
        }
    }, [form.form?.getFieldValue('criteria')])

    useEffect(() => {
        // const otherCoords = form.form?.getFieldValue('genericSpeedEntryCoords')

        setEntryCoordsState(entryCoords)
        form.form?.setFieldsValue({ genericSpeedEntryCoords: entryCoords })
    }, [entryCoords])

    useEffect(() => {
        // const otherCoords = form.form?.getFieldValue('genericSpeedExitCoords')

        setExitCoordsState(exitCoords)
        form.form?.setFieldsValue({ genericSpeedExitCoords: exitCoords })
    }, [exitCoords])

    useEffect(() => {
        if (form) {
            setMode(form.form?.getFieldValue('genericSpeedMode'))
        }
    }, [form.form?.getFieldValue('genericSpeedMode')])

    useEffect(() => {
        if (form) {
            const genericSpeedImpulseUnit = form.form?.getFieldValue('genericSpeedImpulseUnit')

            const isImpulseUnit = (value: string): value is ImpulseUnit => {
                return [ImpulseUnit.SECONDS, ImpulseUnit.METERS, ImpulseUnit.DATES].includes(value as ImpulseUnit)
            }

            if (isImpulseUnit(genericSpeedImpulseUnit)) {
                setImpulsesUnit(genericSpeedImpulseUnit)
            } else if (genericSpeedImpulseUnit === ImpulseUnit.SECONDS) {
                setImpulsesValue(ImpulseValue.SECONDS)
            } else if (genericSpeedImpulseUnit === ImpulseUnit.METERS) {
                setImpulsesValue(ImpulseValue.METERS)
            } else {
                setImpulsesUnit(ImpulseUnit.SECONDS)
                setImpulsesValue(ImpulseValue.SECONDS)
                form.form?.setFieldsValue({ genericSpeedImpulseUnit: ImpulseUnit.SECONDS })
                form.form?.setFieldsValue({ genericSpeedImpulseValue: ImpulseValue.SECONDS })
            }
        }
    }, [form.form?.getFieldValue('genericSpeedImpulseUnit')])

    const initialValueMinSeconds = 1
    const exitThreshold = 25
    const threshold = 25
    const entryThreshold = 25
    return (
        <>
            <Form.Item name={'genericSpeedMode'} label="Mode" className="mb-0" initialValue={mode}>
                <Radio.Group
                    onChange={e => {
                        setMode(e.target.value)
                    }}
                    value={mode}
                >
                    <Radio.Button value={GenericMode.TRACK}>Track</Radio.Button>
                    <Radio.Button value={GenericMode.COORDINATES}>Coordinates</Radio.Button>
                    <Radio.Button value={GenericMode.DATES}>Dates</Radio.Button>
                </Radio.Group>
            </Form.Item>
            {mode === GenericMode.TRACK && (
                <Form.Item
                    name={'genericSpeedThreshold'}
                    className="m-0 w-full"
                    initialValue={threshold}
                    label={
                        <div>
                            <Tooltip title="Deviation meters that the participant can have to get off the track">
                                Deviation meters
                            </Tooltip>
                        </div>
                    }
                >
                    <InputNumber className=" w-full" precision={0} step={1} min={1} value={entryThreshold} />
                </Form.Item>
            )}
            {mode === GenericMode.COORDINATES && (
                <>
                    <Form.Item
                        name={'genericSpeedEntryCoords'}
                        className="m-0 w-full"
                        label={
                            <div>
                                <Tooltip title="Coordinates of the entry point">Entry coords</Tooltip>
                            </div>
                        }
                        initialValue={entryCoordsState}
                        rules={CoordinatesRules}
                    >
                        <CustomInputCoordinates defaultValue={entryCoords} valueForm={entryCoordsState} />
                    </Form.Item>
                    <Form.Item
                        name={'genericSpeedEntryThreshold'}
                        className="m-0 w-full"
                        initialValue={entryThreshold}
                        label={
                            <div>
                                <Tooltip title="Radius of the maximum radius that the participant can have to get the entry point">
                                    Entry radius
                                </Tooltip>
                            </div>
                        }
                    >
                        <InputNumber className=" w-full" precision={0} step={1} min={0} value={entryThreshold} />
                    </Form.Item>

                    <Form.Item
                        name={'genericSpeedExitCoords'}
                        className="m-0 w-full"
                        label={
                            <div>
                                <Tooltip title="Coordinates of the exit point">Exit coords</Tooltip>
                            </div>
                        }
                        initialValue={exitCoords}
                        rules={CoordinatesRules}
                    >
                        <CustomInputCoordinates defaultValue={exitCoords} valueForm={exitCoordsState} />
                    </Form.Item>
                    <Form.Item
                        name={'genericSpeedExitThreshold'}
                        className="m-0 w-full"
                        initialValue={exitThreshold}
                        label={
                            <div>
                                <Tooltip title="Radius of the maximum radius that the participant can have to get the exit point">
                                    Exit radius
                                </Tooltip>
                            </div>
                        }
                    >
                        <InputNumber className=" w-full" precision={0} step={1} min={0} defaultValue={exitThreshold} />
                    </Form.Item>
                </>
            )}
            <>
                <Form.Item name={'criteria'} label="Criteria" className="mb-0" initialValue={criterias[0]}>
                    <SelectCriterias criterias={criterias} criteriaUnique={criteria} />
                </Form.Item>
                <Form.Item name={'genericSpeedTolerance'} label="Tolerance" className="mb-0" initialValue={0}>
                    <InputTolerance criteria={criterias[0]} />
                </Form.Item>
                <Form.Item
                    name={'genericSpeedMinSeconds'}
                    label="Min Seconds"
                    className="mb-0"
                    initialValue={initialValueMinSeconds}
                >
                    <InputNumber
                        className=" w-full"
                        min={1}
                        defaultValue={initialValueMinSeconds}
                        precision={0}
                    ></InputNumber>
                </Form.Item>

                <Form.Item
                    name={'genericSpeedImpulseUnit'}
                    label="Impulses in "
                    className="mb-0"
                    initialValue={impulsesUnit}
                >
                    <Radio.Group
                        onChange={e => {
                            setImpulsesUnit(e.target.value)
                        }}
                        value={impulsesUnit}
                    >
                        <Radio value={ImpulseUnit.METERS}>Meters</Radio>
                        <Radio value={ImpulseUnit.SECONDS}>Seconds</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name={'genericSpeedImpulseValue'}
                    label="Value of impulse"
                    className="mb-0"
                    initialValue={impulsesValue}
                >
                    <InputImpulses impulsesValue={impulsesValue} />
                </Form.Item>
            </>
        </>
    )
}

interface ValueProps {
    impulsesValue: number
    [x: string]: any
}

const InputTolerance = ({ criteria, ...restProps }: any) => {
    useEffect(() => {
        restProps.onChange(criteria.diff_bonus)
    }, [criteria])
    return (
        <InputNumber className=" w-full" value={criteria.diff_bonus} min={0} precision={0} {...restProps}></InputNumber>
    )
}

const InputImpulses = ({ impulsesValue, ...restProps }: ValueProps) => {
    useEffect(() => {
        restProps.onChange(impulsesValue)
    }, [impulsesValue])

    return (
        <InputNumber
            className=" w-full"
            value={impulsesValue}
            defaultValue={impulsesValue}
            min={1}
            precision={0}
            {...restProps}
        ></InputNumber>
    )
}

export default BaseSpecificGenericSpeed
