import { useEffect, useState, type Key } from 'react'
import ErrorBoundarySummaryCard from '../../components/ErrorBoundarySummaryCard/error_boundary_summary_card'
import '../../app.css'
import { useSelectedResultEffect } from '../../hooks/useSelectedResult'
import WaypointsCard from '../../components/ParticipantCard/WaypointsCard/WaypointsCard'
import { defaultSortings } from '../../constants/sortingConstants'
import { useResultsEffect } from '../../hooks/useResults'
import HeaderData from '../../components/dataHeader'
import { Footer } from 'antd/es/layout/layout'
import { byDistance, sortByDistance } from '../matchTrackAnalyzer/matchTrackFunctions'
import WaypointsDownloader from '../../components/ExcelDownloaders/WaypointsDownloader'
import { Divider } from 'antd'
import { defaultMapSettings } from '../../contexts/mapContext'
import { defaultSpeedGraphicSettings } from '../../contexts/speedGraphicContext'
import WaypointsTimeDownloader from '../../components/ExcelDownloaders/WaypointsDownloader/waypointsTime'
import WaypointsPdfDownloader from '../../components/ExcelDownloaders/WaypointsDownloader/waypointsPdf'

interface Props {
    results: WaypointsResult[]
    setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>
    info: RallyAPI
    section: Section
    onClick: (name: string) => void
    setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>
    rallyId: number
    token: string
}
const WaypointsView = ({ results, setSpeedGraphic, setRallyMap, onClick, info, rallyId, token, section }: Props) => {
    const [selectedResult, setSelectedResult] = useState<WaypointsResult | null>(null)
    const [filteredResults, setFilteredResults] = useState<WaypointsResult[]>(results)

    useSelectedResultEffect(selectedResult, setRallyMap, setSpeedGraphic)
    const sortings: SortingObject[] = [
        ...defaultSortings,
        {
            name: 'Tracked distance (ascending)',
            sorting: sortByDistance,
        },
        {
            name: 'Tracked distance (descending)',
            sorting: (a: WaypointsResult[]) => sortByDistance(a, false),
        },
    ]

    const [sorting, setSorting] = useState<(a: Results, b: Results) => number>(() => sortings[4].sorting)

    const applyFilters = (sortedResults: WaypointsResult[]) => {
        setFilteredResults([...sortedResults])
    }
    useResultsEffect(results, sorting, [], applyFilters)
    return (
        <div className="flex h-full flex-col">
            <div className="m-2 ">
                <HeaderData
                    defaultSorting={sortings[4]}
                    sortings={sortings}
                    onChangeSorting={e => setSorting(() => e)}
                    filteredComponentByZone={null}
                    filterComponents={[]}
                />
            </div>
            <div className="h-full w-full flex-1 overflow-y-auto ">
                {filteredResults.map((result: WaypointsResult, index: Number) => {
                    const selected = selectedResult !== null && selectedResult.id === result.id
                    return (
                        <div key={index as Key}>
                            <ErrorBoundarySummaryCard number={result.number} key={result.id}>
                                <WaypointsCard
                                    className={selected ? 'selected' : ''}
                                    result={result}
                                    onClick={(result: WaypointsResult) => {
                                        if (selectedResult !== null && selectedResult.id === result.id) {
                                            setSelectedResult(null)
                                            setRallyMap(defaultMapSettings)
                                            setSpeedGraphic(defaultSpeedGraphicSettings)
                                        } else {
                                            setSelectedResult(result)
                                        }
                                    }}
                                    openPopup={onClick}
                                    rallyId={rallyId}
                                    token={token}
                                    mainSerialNumber={result.serialNumber.mainSerialNumber}
                                />
                            </ErrorBoundarySummaryCard>
                        </div>
                    )
                })}
            </div>
            <div className="mx-2 pt-1">
                <Divider className="m-0 bg-neutral-300" />
            </div>
            <Footer className="m-1 space-x-4 rounded-md bg-neutral-100 p-4 text-center">
                <WaypointsDownloader info={filteredResults} disabled={false} text="Save report as excel" />
                <WaypointsTimeDownloader
                    timezone={info.timezone}
                    info={filteredResults}
                    disabled={false}
                    text="Export waypoints time"
                />
                <WaypointsPdfDownloader
                    infoRally={info}
                    info={filteredResults}
                    disabled={false}
                    text="Export waypoints pdf"
                    section={section}
                />
            </Footer>
        </div>
    )
}

export default WaypointsView
