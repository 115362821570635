import { useCallback, useEffect, useMemo, useState, type Key } from 'react'
import ErrorBoundarySummaryCard from '../../components/ErrorBoundarySummaryCard/error_boundary_summary_card'
import '../../app.css'
import { useSelectedResultEffect } from '../../hooks/useSelectedResult'
import { useResultsEffect } from '../../hooks/useResults'
import { defaultSortings } from '../../constants/sortingConstants'
import HeaderData from '../../components/dataHeader'
import { Footer } from 'antd/es/layout/layout'
import { sortByBestTime, sortByFinishTime, sortByStartTime } from './customZoneFunctions'
import CustomZoneCard from '../../components/ParticipantCard/CustomZoneCard/customZoneCard'
import { TimeInZoneState } from '../../constants/timeInZoneEnum'
import { aproachTimeValues } from '../../constants/aprroachTimeValues'
import { timespanToHHMMSS } from '../../utils'
import CustomZoneDownloader from '../../components/ExcelDownloaders/CustomZoneDownloader'
import { Divider } from 'antd'

interface Props {
    results: CustomZoneResult[]
    setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>
    info: RallyAPI
    onClick: (lon: number | null, lat: number | null) => void
    setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>
}
const sortings: SortingObject[] = [
    ...defaultSortings,
    {
        name: 'Start time (ascending)',
        sorting: sortByStartTime,
    },
    {
        name: 'Start time (descending)',
        sorting: (a: CustomZoneResult[]) => sortByStartTime(a, false),
    },
    {
        name: 'Finish time (ascending)',
        sorting: sortByFinishTime,
    },
    {
        name: 'Finish time (descending)',
        sorting: (a: CustomZoneResult[]) => sortByFinishTime(a, false),
    },
    {
        name: 'Best time (ascending)',
        sorting: sortByBestTime,
    },
    {
        name: 'Best time (descending)',
        sorting: (a: CustomZoneResult[]) => sortByBestTime(a, false),
    },
]
const CustomZoneView = ({ results, setSpeedGraphic, setRallyMap, info, onClick }: Props) => {
    const [selectedResult, setSelectedResult] = useState<CustomZoneResult | null>(null)
    const [maxSpeedInfrigments, setMaxSpeedInfrigments] = useState<boolean>(false)
    const [minTimeInfrigments, setMinTimeInfrigments] = useState<boolean>(false)
    const [aproachTimeValue, setAproachTimeValue] = useState<string>(aproachTimeValues[0].label)
    const [filteredResults, setFilteredResults] = useState<CustomZoneResult[]>(results)
    useSelectedResultEffect(selectedResult, setRallyMap, setSpeedGraphic)

    const [sorting, setSorting] = useState<(a: Results, b: Results) => number>(() => sortings[0].sorting)

    const changeMaxSpeedInfrigmentsCheckbox = (event: any) => {
        setMaxSpeedInfrigments(event.target.checked)
    }

    const changeMinTimeInfrigmentsCheckbox = (event: any) => {
        setMinTimeInfrigments(event.target.checked)
    }

    const filteredComponentsByModes: ComponentCheckbox[] = [
        {
            name: 'Show max speed infrigments mode',
            checked: maxSpeedInfrigments,
            function: changeMaxSpeedInfrigmentsCheckbox,
        },
        {
            name: 'Show min time infrigments mode',
            checked: minTimeInfrigments,
            function: changeMinTimeInfrigmentsCheckbox,
        },
    ]

    const maxSpeedFilter = (result: CustomZoneResult) => {
        const filteredLaps = result.data.laps.filter((res2: Laps) => {
            return res2.speedInfo.fastestRange !== null
        })
        if (filteredLaps) {
            result.data.laps = filteredLaps
        }

        return result
    }

    const minTimeFilter = (result: CustomZoneResult) => {
        const filteredLaps = result.data.laps.filter((res2: Laps) => {
            return res2.minTimeInZone === TimeInZoneState.UNDER
        })
        if (filteredLaps) {
            result.data.laps = filteredLaps
        }
        return result
    }

    const approachStartTime = (result: CustomZoneResult, startTime: string) => {
        const laps = result.data.laps
        let c = 0
        if (laps.length > 0) {
            laps.forEach((result: Laps) => {
                const a = result.entry.timeUtc
                if (a != null) {
                    const b = timespanToHHMMSS(a).split(':')[2]
                    switch (startTime) {
                        case 'default':
                            result.entry.approachTimeUtc = null
                            break
                        case '30':
                            c = Math.trunc(Number(b) / 30) * 30
                            result.entry.approachTimeUtc = result.entry.timeUtc - (Number(b) - c)
                            break
                        case '60':
                            c = Math.trunc(Number(b) / 60) * 60
                            result.entry.approachTimeUtc = result.entry.timeUtc - (Number(b) - c)
                            break

                        default:
                            break
                    }
                }
            })
        }
        return result
    }

    const filterApply = (result: CustomZoneResult) => {
        const newResult = Object.assign({}, result)
        newResult.data = Object.assign({}, result.data)
        if (maxSpeedInfrigments) {
            maxSpeedFilter(newResult)
        }
        if (minTimeInfrigments) {
            minTimeFilter(newResult)
        }
        return newResult
    }
    const applyFilters = (sortedResults: CustomZoneResult[]) => {
        const approached = sortedResults.map(e => approachStartTime(e, aproachTimeValue))
        let shownResults = approached.map(e => filterApply(e))

        setFilteredResults(shownResults)
    }

    useResultsEffect(results, sorting, [maxSpeedInfrigments, minTimeInfrigments, aproachTimeValue], applyFilters)

    const changeApproachTimeFilter = (event: string) => {
        setAproachTimeValue(event)
    }

    const filteredComponentByApproachTime: SelectComponent = {
        name: 'Zone',
        values: aproachTimeValues,
        value: aproachTimeValue,
        onChange: changeApproachTimeFilter,
    }

    return (
        <div className="relative flex h-full w-full flex-col">
            <div className=" ">
                <HeaderData
                    defaultSorting={sortings[0]}
                    sortings={sortings}
                    onChangeSorting={e => setSorting(() => e)}
                    filteredComponentByZone={filteredComponentByApproachTime}
                    filterComponents={filteredComponentsByModes}
                ></HeaderData>
            </div>

            <div className="h-full w-full flex-1 overflow-y-auto">
                {filteredResults.map((result: CustomZoneResult, index: Number) => {
                    const selected = selectedResult !== null && selectedResult.id === result.id
                    return (
                        <div key={index as Key}>
                            <ErrorBoundarySummaryCard number={result.number} key={result.id}>
                                <CustomZoneCard
                                    className={selected ? 'selected' : ''}
                                    result={result}
                                    onClick={(result: CustomZoneResult) => setSelectedResult(result)}
                                    timezone={info.timezone}
                                    updatePointer={onClick}
                                    participantTracks={result.participantTracks}
                                />
                            </ErrorBoundarySummaryCard>
                        </div>
                    )
                })}
            </div>
            <div className="mx-2 pt-1">
                <Divider className="m-0 bg-neutral-300" />
            </div>
            <Footer className="m-1 rounded-md bg-neutral-100 p-4 text-center ">
                <CustomZoneDownloader
                    timezone={info.timezone}
                    info={filteredResults}
                    disabled={false}
                    text="Save report as excel"
                ></CustomZoneDownloader>
            </Footer>
        </div>
    )
}

export default CustomZoneView
