import React, { useState } from 'react'
import ParticipantCard from '../participantCard'
import RangeBar from '../../RangeBar/rangeBar'
import 'moment-timezone'
import './waypoints-card.css'
import DnsDnf from './DnsDnf'
import PositionsLost from '../../PositionsLost'
import { calculateLostPositions } from '../../../utils'
import WaypointsFailed from './WaypointsFailed'
import { getASSWaypoint, getDSSWaypoint } from '../../../analyzer/waypointsAnalyzer/waypointsFunctions'
import WaypointsTable from './WaypointsTable'
interface Props {
    className: string
    onClick: any
    result: WaypointsResult
    openPopup: any
    rallyId: number
    token: string
    mainSerialNumber: number
}
const WaypointsCard = ({ className, onClick, result, openPopup, rallyId, token, mainSerialNumber }: Props) => {
    if (!result.data) {
        return <></>
    }
    const [state, setState] = useState<string>(result.data.valueDnsDnf)
    let lastWaypoint: number = 0
    let inactiveStartsAt: number
    let mainPositions = calculateLostPositions(result.disconnectedGpsTrack.main)
    let secondaryPositions = calculateLostPositions(result.disconnectedGpsTrack.secondary)
    const wpDSS = getDSSWaypoint(result.data.waypoints)
    const wpASS = getASSWaypoint(result.data.waypoints)
    const unmatchedWaypoints: RangeBar[] = result.data.waypoints.map(
        (waypoint: Waypoint, i: number, waypoints: any) => {
            return {
                edges: [(i / waypoints.length) * 100, ((i + 1) / waypoints.length) * 100],
                disabled: waypoint.excluded || false,
                matched: waypoint.matched,
                matchedGps: waypoint.matchedGps,
                blocked: waypoint.blocked,
                firstUnfinished : waypoint.firstUnfinished
            }
        }
    )
    for (let i = result.data.waypoints.length - 1; i >= 0; i--) {
        if (result.data.waypoints[i].matched || result.data.waypoints[i].matchedGps) {
            lastWaypoint = i + 1
            break
        }
    }
    inactiveStartsAt = (lastWaypoint! / result.data.waypoints.length) * 100 || 0

    if (!unmatchedWaypoints.length) {
        return <></>
    }

    const wpFailedWithoutValidate = result.data.waypoints.filter(
        (e: Waypoint, index: number) => !e.matched && !e.matchedGps && index < lastWaypoint && e.blocked === false
    )
    const wpFailedValidated = result.data.waypoints.filter(
        (e: Waypoint, index: number) => !e.matchedGps && index < lastWaypoint && e.blocked === true
    )

    const wpPassedWithoutValidate = result.data.waypoints.filter(
        (e: Waypoint) => e.matched && e.blocked === false
    )
    
    let collapseData = <></>
    if (wpFailedWithoutValidate.length > 0 || wpPassedWithoutValidate.length > 0) {
        collapseData = (
            <div className="flex flex-row space-x-1 pb-2">
                <div className="flex-1 flex-col rounded border-[1px] border-solid border-neutral-400 bg-neutral-300">
                    <div className="p-1 text-center text-sm font-bold">Missed to verify</div>
                    <div className="divide-x bg-neutral-200">
                        <WaypointsTable
                            mainSerialNumber={mainSerialNumber}
                            openPopup={openPopup}
                            participantId={result.id}
                            subrallyId={rallyId}
                            token={token}
                            waypoints={wpFailedWithoutValidate}
                        />
                    </div>
                </div>
                <div className="flex-1 flex-col rounded border-[1px] border-solid border-neutral-400 bg-neutral-300">
                    <div className="p-1 text-center text-sm font-bold">Passed to verify</div>
                    <div className="bg-neutral-200">
                        <WaypointsTable
                            mainSerialNumber={mainSerialNumber}
                            openPopup={openPopup}
                            participantId={result.id}
                            subrallyId={rallyId}
                            token={token}
                            waypoints={wpPassedWithoutValidate}
                        />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <ParticipantCard
            type="Waypoints"
            number={result.number}
            rallyId={result.rally}
            onClick={() => onClick(result)}
            className={`${className} waypoint-card m-1 items-center`}
            collapseData={collapseData}
        >
            <RangeBar
                ranges={unmatchedWaypoints}
                inactiveStart={inactiveStartsAt}
                width={100}
                state={state}
                theme="match-track"
                type="waypoints-report"
            />
            <WaypointsFailed
                numberOfWpNotValidated={wpFailedWithoutValidate.length}
                numberOfWpValidated={wpFailedValidated.length}
            />
            <PositionsLost positions={mainPositions + secondaryPositions} />
            <DnsDnf
                dnsDnf={state}
                wpASS={wpASS}
                wpDSS={wpDSS}
                updateState={setState}
                participantId={result.id}
                subrallyId={rallyId}
                token={token}
                mainSerialNumber={mainSerialNumber}
            />
        </ParticipantCard>
    )
}

export default React.memo(WaypointsCard)
