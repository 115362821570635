import { useEffect, useState, type Key } from 'react'
import ErrorBoundarySummaryCard from '../../components/ErrorBoundarySummaryCard/error_boundary_summary_card'
import '../../app.css'
import { useSelectedResultEffect } from '../../hooks/useSelectedResult'
import { useResultsEffect } from '../../hooks/useResults'
import { defaultSortings } from '../../constants/sortingConstants'
import HeaderData from '../../components/dataHeader'
import { Footer } from 'antd/es/layout/layout'
import NeutZonesDownloader from '../../components/ExcelDownloaders/NeutZonesDownloader'
import NeuZonesCard from '../../components/ParticipantCard/NeutralizationCard/neutZonesCard'
import { sortByStartTime } from './neutralizationFunctions'
import { Divider } from 'antd'

interface Props {
    results: NeutralizationResult[]
    setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>
    info: RallyAPI

    onClick: (lon: number, lat: number) => void
    setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>
}
const NeutralizationView = ({ setSpeedGraphic, results, setRallyMap, info, onClick }: Props) => {
    const [selectedResult, setSelectedResult] = useState<NeutralizationResult | null>(null)
    const [filteredResults, setFilteredResults] = useState<NeutralizationResult[]>(results)
    const [infrigments, setInfrigments] = useState<boolean>(false)
    const changeInfrigmentsCheckbox = (event: any) => {
        setInfrigments(event.target.checked)
    }
    const filteredComponentsByModes = [
        {
            name: 'Show infrigments mode',
            checked: infrigments,
            function: changeInfrigmentsCheckbox,
        },
    ]
    const ZONE_ALL = 'ALL'
    const [filterZoneName, setFilterZoneName] = useState<string>(ZONE_ALL)
    const changeZoneNameFilter = (name: string) => {
        setFilterZoneName(name)
    }

    const zoneNames = [
        ZONE_ALL,
        ...results[0].data.results.map((zone: Neutralizations) => {
            if (zone !== undefined) {
                return zone.init.name
            }
            return []
        }),
    ].map(e => {
        if (Array.isArray(e)) {
            return { value: e[0], label: e[0] }
        }
        return { value: e, label: e }
    })

    const filteredComponentByZone = {
        name: 'Zone',
        values: zoneNames,
        value: filterZoneName,
        onChange: changeZoneNameFilter,
    }

    useSelectedResultEffect(selectedResult, setRallyMap, setSpeedGraphic)

    const sortings: SortingObject[] = [
        ...defaultSortings,
        {
            name: 'Start time (ascending)',
            sorting: sortByStartTime,
        },
        {
            name: 'Start time (descending)',
            sorting: (a: NeutralizationResult[]) => sortByStartTime(a, false),
        },
    ]
    const infrigmentsFilter = (result: NeutralizationResult) => {
        result.data.results = result.data.results.filter(zone => {
            return zone.penalization
        })

        return result
    }
    const [sorting, setSorting] = useState<(resultA: Results, resultB: Results) => number>(() => sortings[4].sorting)

    const filterApply = (result: NeutralizationResult) => {
        const newResult = Object.assign({}, result)
        newResult.data = Object.assign({}, result.data)
        zonesFilter(newResult)
        if (infrigments) {
            infrigmentsFilter(newResult)
        }
        return newResult
    }

    const applyFilters = (sortedResults: NeutralizationResult[]) => {
        let shownResults = sortedResults.map(e => filterApply(e))

        setFilteredResults(shownResults)
    }

    useResultsEffect(results, sorting, [filterZoneName, infrigments], applyFilters)

    const zonesFilter = (result: NeutralizationResult) => {
        const newResultFiltered = (result.data as NeutralizationsReport).results.filter(
            (zoneEntry: Neutralizations) => [ZONE_ALL, zoneEntry.init.name].indexOf(filterZoneName) !== -1
        )

        result.data.results = newResultFiltered
        return result
    }

    return (
        <div className="flex h-full flex-col">
            <div className="m-2 ">
                <HeaderData
                    defaultSorting={sortings[4]}
                    sortings={sortings}
                    onChangeSorting={e => setSorting(() => e)}
                    filteredComponentByZone={filteredComponentByZone}
                    filterComponents={filteredComponentsByModes}
                ></HeaderData>
            </div>
            <div className="h-full flex-1 overflow-y-auto p-2">
                {filteredResults.map((result: NeutralizationResult, index: Number) => {
                    const selected = selectedResult !== null && selectedResult.id === result.id
                    return (
                        <div key={index as Key}>
                            <ErrorBoundarySummaryCard number={result.number} key={result.id}>
                                <NeuZonesCard
                                    className={selected ? 'selected' : ''}
                                    result={result}
                                    onClick={(result: NeutralizationResult) => setSelectedResult(result)}
                                    timezone={info.timezone}
                                    updatePointer={onClick}
                                    participantTracks={result.participantTracks}
                                />
                            </ErrorBoundarySummaryCard>
                        </div>
                    )
                })}
            </div>
            <div className="mx-2 pt-1">
                <Divider className="m-0 bg-neutral-300" />
            </div>
            <Footer className="m-1 rounded-md bg-neutral-100 p-4 text-center ">
                <NeutZonesDownloader
                    timezone={info.timezone}
                    info={filteredResults}
                    disabled={false}
                    text="Save report as excel"
                ></NeutZonesDownloader>
            </Footer>
        </div>
    )
}

export default NeutralizationView
