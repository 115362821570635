import { handlerFiltersPutBrand } from '../../utils'
import Analyzer from '../analyzer'
import { getDevicesStatistics, handlerFiltersPutGenericSpeed } from './genericSpeedFunctions'

export default class GenericSpeedAnalyzer extends Analyzer {
    genericSpeedResult: any[]

    constructor(
        rallyId: number,
        subRallyId: number,
        section: Section,
        excluded: any,
        connections: DevicesConnections,
        routesReports: any
    ) {
        super(rallyId, subRallyId, section, excluded, connections, routesReports)
        this.genericSpeedResult = []
    }

    public createWorkers(participants: Participant[], maxWorkers: number) {
        super.createWorkers(participants, maxWorkers, 'generic-speed')
    }
    fetchResults = async (
        participants: Participant[],
        data: DataRallyForm,
        token: string,
        trackCaches: TrackCaches,
        brandFilters: any,
        modeSandBox: boolean
    ) => {
        const specificParams = this._getRequestParamsSpecific(data)
        const devices: Devices[] = await getDevicesStatistics(token, this.subrallyId)
        let brands = null
        if (!modeSandBox) {
            brands = await handlerFiltersPutGenericSpeed(
                this.subrallyId,
                this.section.section,
                token,
                data.detectionType,
                specificParams,
                brandFilters
            )
        }
        if (brands) return brands

        for (let participant of participants) {
            const caches = trackCaches[participant.id]
            const maxSpeed = Math.max(
                ...devices
                    .filter(device => device.participant_id === participant.id && device.max_speed)
                    .map(device => device.max_speed as number)
            )

            const params = this._getRequestParams(
                this.rallyId,
                this.subrallyId,
                this.section,
                participant,
                data,
                token,
                caches,
                this.connections,
                maxSpeed
            )
            const worker = await this._pool.get()
            if (worker == null) {
                break
            }
            worker.onmessage = async (e: MessageEvent<any>) => {
                const messageType: string = e.data.type
                switch (messageType) {
                    case 'cacheUpdate':
                        this.updateCache(e)
                        return
                    case 'error':
                        this.updateError(e)
                        return
                    case 'genericSpeedResult':
                        const generalParamsForReport = e.data.generalParamsForReport
                        const resultParticipant = e.data.participant
                        let resultInfo: GenericSpeedReport = e.data.result

                        const disconnectedGpsTrack = generalParamsForReport.disconnectedGpsTrack
                        const result: any = {
                            highlightedPoints: resultInfo.highlightedPoints,
                            detectionType: data.detectionType,
                            id: resultParticipant.id,
                            number: resultParticipant.numero,
                            data: resultInfo,
                            sectionId: this.section.section,
                            rally: this.subrallyId,
                            token: token,
                            participantTracks: generalParamsForReport.participantTracks || [],
                            connections: generalParamsForReport.connections,
                            areaPoints: resultInfo.areaPoints,
                            customTracks: [],
                            disconnectedGpsTrack: disconnectedGpsTrack,
                        }
                        this.genericSpeedResult = [...this.genericSpeedResult, result]
                        this.onResults(this.genericSpeedResult)
                        break
                    default:
                        return
                }
                this._pool.put(worker)
            }
            worker.postMessage(['genericSpeedProcess', params])
        }

        while (this._pool.activeWorkers.length > 0) {
            await new Promise(r => setTimeout(r, 0))
        }

        this._pool.free()

        console.timeEnd('analysis')
    }

    getResults = () => this.genericSpeedResult
    _getRequestParamsSpecific = (data: DataRallyForm) => {
        return {
            genericSpeedMode: data.detectionTypeData.genericSpeedMode,
            genericSpeedModeThreshold: data.detectionTypeData?.genericSpeedThreshold
                ? Number(data.detectionTypeData?.genericSpeedThreshold)
                : null,
            criteria: data.detectionTypeData.criteria,
            genericSpeedEntryCoords: data.detectionTypeData?.genericSpeedEntryCoords,
            // genericSpeedEntryLatitude: data.detectionTypeData?.genericSpeedEntryCoords[0]
            //     ? Number(data.detectionTypeData?.genericSpeedEntryCoords[0])
            //     : null,
            // genericSpeedEntryLongitude: data.detectionTypeData?.genericSpeedEntryCoords[1]
            //     ? Number(data.detectionTypeData?.genericSpeedEntryCoords[1])
            //     : null,
            genericSpeedEntryThreshold: data.detectionTypeData?.genericSpeedEntryThreshold
                ? Number(data.detectionTypeData?.genericSpeedEntryThreshold)
                : null,
            genericSpeedExitCoords: data.detectionTypeData?.genericSpeedExitCoords,
            // genericSpeedExitLatitude: data.detectionTypeData?.genericSpeedExitCoords[0]
            //     ? Number(data.detectionTypeData?.genericSpeedExitCoords[0])
            //     : null,
            // genericSpeedExitLongitude: data.detectionTypeData?.genericSpeedExitCoords[1]
            //     ? Number(data.detectionTypeData?.genericSpeedExitCoords[1])
            //     : null,
            genericSpeedExitThreshold: data.detectionTypeData?.genericSpeedExitThreshold
                ? Number(data.detectionTypeData?.genericSpeedExitThreshold)
                : null,
            genericSpeedImpulseUnit: data.detectionTypeData.genericSpeedImpulseUnit,
            genericSpeedImpulseValue: data.detectionTypeData.genericSpeedImpulseValue,
            genericSpeedMinSeconds: data.detectionTypeData.genericSpeedMinSeconds,
            genericSpeedTolerance: data.detectionTypeData.genericSpeedTolerance,
        }
    }

    _getRequestParams = (
        rallyId: number,
        subrallyId: number,
        section: Section,
        participant: Participant,
        data: DataRallyForm,
        token: string,
        caches: TrackCache,
        connections: DevicesConnections,
        maxSpeed: number
    ): ParamsAnalyzerGenericSpeed => {
        let entryCoords
        let exitCoords
        if (Array.isArray(data.detectionTypeData?.genericSpeedEntryCoords)) {
            entryCoords = data.detectionTypeData?.genericSpeedEntryCoords
        } else {
            entryCoords = data.detectionTypeData?.genericSpeedEntryCoords.split(',')
        }
        if (Array.isArray(data.detectionTypeData?.genericSpeedExitCoords)) {
            exitCoords = data.detectionTypeData?.genericSpeedExitCoords
        } else {
            exitCoords = data.detectionTypeData?.genericSpeedExitCoords.split(',')
        }
        const reqData: ParamsAnalyzerGenericSpeed = {
            token: token,
            subrallyId: subrallyId,
            rallyId: rallyId,
            section: section,
            from: data.from,
            to: data.to,
            participantTrackType: data.participantTrackType,
            participantUINumber: participant.numero,
            detectionType: data.detectionType,
            caches: caches,
            connections,
            participant: participant,
            maxSpeed: maxSpeed,
            threshold: -1,
            genericSpeedMode: data.detectionTypeData.genericSpeedMode,
            genericSpeedModeThreshold: Number(data.detectionTypeData?.genericSpeedThreshold),
            criteria: data.detectionTypeData.criteria,
            genericSpeedEntryLatitude: Number(entryCoords[0]),
            genericSpeedEntryLongitude: Number(entryCoords[1]),
            genericSpeedEntryThreshold: Number(data.detectionTypeData?.genericSpeedEntryThreshold),
            genericSpeedExitLatitude: Number(exitCoords[0]),
            genericSpeedExitLongitude: Number(exitCoords[1]),
            genericSpeedExitThreshold: Number(data.detectionTypeData?.genericSpeedExitThreshold),
            genericSpeedImpulseUnit: data.detectionTypeData.genericSpeedImpulseUnit,
            genericSpeedImpulseValue: data.detectionTypeData.genericSpeedImpulseValue,
            genericSpeedMinSeconds: data.detectionTypeData.genericSpeedMinSeconds,
            genericSpeedTolerance: data.detectionTypeData.genericSpeedTolerance,
        }

        return reqData
    }
}
