import { Button } from 'antd'
import { waypointsPdf } from '../../../pdfReport/waypointsPdf'
interface Props {
    info: WaypointsResult[]
    text: string
    disabled: boolean
    infoRally: RallyAPI
    section: Section
}
const WaypointsPdfDownloader = ({ info, text, disabled, infoRally, section }: Props) => {
    return (
        <Button
            onClick={() => {
                waypointsPdf(info, infoRally, section)
            }}
            disabled={disabled}
        >
            {text}
        </Button>
    )
}

export default WaypointsPdfDownloader
