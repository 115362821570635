import { timespanToHHMMSS, timeUtcToHHMMSS } from '../../utils'
import { Document, Page, Text, View, Image } from '@react-pdf/renderer'
import * as ReactPDF from '@react-pdf/renderer'
import moment from 'moment'
import { getStylesPDF } from '../tools/getStyles'

interface PDFData {
    bib: string
    arr: Waypoint[]
    penalty: string
}
export const waypointsPdfData = (
    info: WaypointsResult[],
    infoRally: RallyAPI,
    section: Section
): React.ReactElement<ReactPDF.DocumentProps> => {
    // Format pdf data
    let arr: PDFData[] = []
    let lastWaypoint: number = 0
    for (const result of info) {
        if (result.data.waypoints.length > 0) {
            for (let i = result.data.waypoints.length - 1; i >= 0; i--) {
                if (result.data.waypoints[i].matched || result.data.waypoints[i].matchedGps) {
                    lastWaypoint = i + 1
                    break
                }
            }
            const wpFailedWithoutValidate = result.data.waypoints.filter(
                (e: Waypoint, index: number) =>
                    !e.matched && !e.matchedGps && index < lastWaypoint && e.blocked === true
            )
            if (wpFailedWithoutValidate.length > 0) {
                arr.push({
                    bib: result.number,
                    arr: wpFailedWithoutValidate,
                    penalty: timespanToHHMMSS(wpFailedWithoutValidate.length * 900),
                })
            }
        }
    }

    // Styles of pdf page
    const styles = getStylesPDF()

    // Banner of pdf top
    let bannerLogoTop = ''

    // Name of Rally
    let name = ''
    // No length it means that this rally has no timing
    if (infoRally.linked_races.length > 0) {
        const linkedRace = infoRally.linked_races[0]
        name = linkedRace.name
        bannerLogoTop = `${linkedRace.images_url}${linkedRace.banner_top}`
    } else {
        bannerLogoTop = infoRally.url_logo
    }

    // Descriptive text of pdf
    const descriptiveText = 'Report of missing waypoints'

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section} fixed>
                    <Image style={{ height: 70, width: '100%' }} src={bannerLogoTop} cache={false} />
                    <Text>{name}</Text>
                    <Text>
                        {descriptiveText}    Tramo {section.name_timing}
                    </Text>
                </View>
                <TableContent data={arr} styles={styles} />
                <View style={styles.footer}>
                    <Text>Fecha: {moment(Date.now()).tz(infoRally.timezone).format('DD-MM-YYYY')}</Text>
                    <Text>Hora{timeUtcToHHMMSS(Date.now() / 1000, infoRally.timezone)}</Text>
                </View>
            </Page>
        </Document>
    )
}

interface TableContentProp {
    data: PDFData[]
    styles: ReactPDF.Styles
}
const TableContent = ({ data, styles }: TableContentProp) => (
    <View style={styles.table}>
        <View style={[styles.tableRow, { fontSize: 8 }]} fixed>
            <View style={styles.tableColHeader} fixed>
                <Text>No.</Text>
            </View>
            <View style={styles.tableColHeader} fixed>
                <Text>Waypoints</Text>
            </View>
            <View style={styles.tableColHeader} fixed>
                <Text>Penalty</Text>
            </View>
        </View>
        {data.map((fila, index) => (
            <View
                style={[
                    styles.tableRow,
                    { backgroundColor: index % 2 === 0 ? '#ffffff' : '#e9e9ea' }, // Alterna blanco y gris
                ]}
                key={fila.bib}
                wrap={false}
            >
                <View style={styles.tableCol} wrap={false}>
                    <Text style={styles.tableCell}>{fila.bib}</Text>
                </View>

                <View style={styles.tableCol} wrap={false}>
                    <Text style={styles.tableCell}>{fila.arr.map((value: Waypoint) => value.name).join(', ')}</Text>
                </View>
                <View style={styles.tableCol} wrap={false}>
                    <Text style={styles.tableCell}>{fila.penalty}</Text>
                </View>
            </View>
        ))}
    </View>
)
