import { StyleSheet } from '@react-pdf/renderer'
import * as ReactPDF from '@react-pdf/renderer'

export function getStylesPDF(): ReactPDF.Styles {
    return StyleSheet.create({
        page: {
            padding: 25,
            fontSize: 12,
        },
        table: {
            display: 'flex',
            width: 'auto',
            borderBottom: '1px solid #000',
        },
        section: {
            marginBottom: 10,
            alignItems: 'center',
            fontSize: 14,
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableColHeader: {
            width: '33.3%',
            borderBottom: '1px solid black',
            borderTop: '1px solid black',
            padding: 5,
            textAlign: 'center',
            color: '#8d8d8d',
        },
        tableCol: {
            width: '33.3%',
            padding: 5,
            textAlign: 'center',
            fontSize: 10,
        },
        tableCell: {
            height: 'auto',
            fontSize: 10,
        },
        footer: {
            marginTop: 10,
            textAlign: 'center',
        },
    })
}
